<template>
 <div class='examresultsdetailBox'>
    <div class="examresultsdetailBox-content _clear">
        <div class="examresultsdetailBox-content-left">
            <h4>{{resultsobj.examName}}<span>批阅完成</span></h4>
            <div class="examresultsdetailBox-content-left-detailed">
                <span>答题人：喵喵桑</span>
                <span>交卷时间：{{resultsobj.examStartTime}}</span>
                <span>用时：{{resultsobj.useDuration}}</span>
            </div>
        </div>
        <div class="examresultsdetailBox-content-right">
            <h4><span>{{resultsobj.passingScore}}</span>分</h4>
            <p>总分：{{resultsobj.totalScore}}分</p>
        </div>
        
    </div>
    <img class="examresultsdetailBox-img" :src="resultsobj.isPass==0?bjg:jg" alt="">
 </div>
</template>

<script>
export default {
    name:'examresultsdetail',
    props: {
        resultsobj: {
            type: Object,
            default() {
                return {
                };
            },
        },
    },
    data () {
        return {
            bjg:require('../../asset/images/bjg.png'),
            jg:require('../../asset/images/jg.png'),
        };
    },

    methods: {},

    mounted(){
    },
}

</script>
<style lang='scss' scoped>
    @import "../../asset/css/examresults.scss"
</style>