<template>
    <div class="div-border" style="position: relative">
        <el-upload
            action=""
            accept=".pdf,.zip,.rar,.docx,.xls,.xlsx,.doc,.png,.jpg,.jpeg,.gif,.mp3,.mp4"
            :http-request="Upload"
            :before-upload="beforeUpload"
            :before-remove="beforeRemove"
            :on-change="uploadZipProcess"
            :on-exceed="handleExceed"
            :on-success="handleSuccess"
            :show-file-list="false"
            :multiple="false"
            ref="uploadZip"
            style="width: 100%"
            :disabled="enclosure.length == 10"
        >
            <el-button
                class="sub"
                plain
                :style="{cursor: enclosure.length == 10 ? 'no-drop': 'pointer'}">
                添加附件
                <em class="el-icon-circle-plus-outline"></em>      
            </el-button>
            <div slot="tip" class="upload-tip">
              10个以内，单个文件小于1G;
              支持文档（pdf、doc、docx、xls、xlsx);
              图片（png、jpeg、jpg、gif);
              音频,视频（mp3、mp4);
              压缩包（ rar、zip ）格式
            </div>
            <div
              @click="(event)=>{event.stopPropagation()}"
              style="cursor:default"
            >
              <div class="zipList"
                v-for="(item, index) in enclosure"
                :key="index"
                v-show="enclosure.length"
              >
                <span class="el-icon-tickets"></span>
                <span class="file-name">{{ item.name | nameFormat }}</span>
                <span>{{item.name | suffixFilter}}</span>
                <span @click="clearFile($event,item,index)" class="el-icon-close cl"></span>
              </div>
            </div>

        </el-upload>
        <!-- 进度条 -->
        <el-progress
            class="progr"
            v-show="progressFlag"
            :percentage="loadProgress"
            :show-text="false"
            :stroke-width="2"
        ></el-progress>
    </div>
</template>
<script>

export default {
    name: "uploadZip",
    props: {
        question:{
            type:Object
        },
      enclosureN: {
        type: String
      }
    },
    data() {
        return {
            datas: [],
            ossClient:null, // oss客户端实例
            enclosure: [],
            upTitle: '上传附件' +'1',
            fileIs: false,
            loadProgress: 0, // 动态显示进度条
            progressFlag: false, // 关闭进度条
            fileList: [],
            fileName: '',
            fileSuffix: [],
            fileUp: true,
            fileData: {
              name: '',
            },
            fileOff: false,
        }
    },
    watch: {
        // 'enclosure.enclosureName' (val) {
        //     console.debug(val)
        //     if (this.enclosure.enclosureName) {
        //         this.upTitle = '更换附件'
        //     } else {
        //         this.upTitle = '上传附件'
        //     }
        // },
        enclosureN:{
            handler(val){
                if (val) {
                  this.enclosure = JSON.parse(val)
                  console.log('this.enclosure----->',this.enclosure)
                }
                // this.enclosure.enclosureName = val;
                // if (val) {
                //     this.fileUp = false;
                // } else {
                //     this.fileUp = true;
                // }
                this.fileIs = true;
            },
            deep: true
        },
    },
    filters: {
      nameFormat: function(msg) {
        const fileStr = msg.lastIndexOf('.');
        return msg.slice(0,fileStr)
      },
      suffixFilter: function(msg) {
        const suffix = msg.lastIndexOf('.');
        return msg.slice(suffix)
      }
    },
    methods: {
        Upload (file) {
          let formData = new FormData();
          formData.append("file", file.file);
        },
        init () {
            this.enclosure.enclosureName = this.enclosureN
            if (this.enclosure.enclosureName) {
                this.upTitle = '更换附件'
            } else {
                this.upTitle = '上传附件'
            }
        },
        //判断css文件大小
        async beforeUpload(file) {
          if (this.enclosure.length == 10) return;
            this.loadProgress = 0;
            let types = ["mp3","mp4","pdf","zip","rar","xls","docx","xlsx","doc","png","jpg","jpeg","gif"];
            var fileExt = file.name.replace(/.+\./, "");
            if (types.indexOf(fileExt.toLowerCase()) === -1) {
                this.$message.error('上传文件格式不正确!');
                this.fileIs = false;
                this.progressFlag = false
                return ;
            }
            const isLtSize = file.size  < (1024 * 1024 * 1024);
            if (!isLtSize) {
                this.$message.error('上传文件大小不能超过1G!');
                this.fileIs = false;
                this.progressFlag = false
                return;
            }
            this.datas = await this.$api.resource.getAliyunDocConf().catch(()=>{
                this.fileIs = false;
                this.$message({
                    showClose: true,
                    message: '上传失败',
                    type: 'error'
                })
                this.progressFlag = false
            })
            await this.initOSSClient(this.datas);
            this.uploadAll(file);
            // return;
        },
        async initOSSClient (datas) {
            const OSS = require('ali-oss');
            this.ossClient = new OSS({
                region: datas.data.region,
                accessKeyId: datas.data.accessKeyId,
                accessKeySecret: datas.data.accessKeySecret,
                // bucket: datas.data.scormBucket, /* 装图片的桶名 */
               bucket: datas.data.bucketName, /* 装图片的桶名 */
              stsToken: datas.data.securityToken,
               endpoint: datas.data.endpoint, // 新增加
            });
        },
        //拼接文档名
        creatFileUrl(name) {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const timestamp = new Date().getTime();
            const fileSuffix = name.lastIndexOf('.');
            const fileExt = name.substring(fileSuffix);// 后缀名
            const storeAs = `${timestamp}${fileExt}`;
           return `${year}/${this.add0(month)}/${storeAs}`
        },
        add0(m) {
            return m < 10 ? `0${m}` : m;
        },
        uploadAll (files) {
            const fileInfo = {
                file: files,
                name: files.name,
                size: files.size,
                path: this.creatFileUrl(files.name)
            }
          this.resumeMultipartUpload(fileInfo)
        },
        // 断点续传
        async resumeMultipartUpload (obj) {
          const uploadUrl = `https://${this.datas.data.bucketName}.oss-cn-beijing.aliyuncs.com/cms/${obj.path}`;
          let fileName =`cms/${obj.path}`;
          try {
                const self = this;
                await self.ossClient.multipartUpload(fileName, obj.file, {
                    progress: function (percentage) {
                      const preNum = parseInt(Math.ceil(percentage * 100));
                      self.loadProgress = preNum;
                    }
                }).then(() => {
                    let item = {
                      url: uploadUrl,
                      name: obj.file.name,
                      size: parseInt(obj.file.size / 1024) || 0
                    }
                    this.enclosure.push(item)
                    this.$message({
                        showClose: true,
                        message: '上传成功',
                        type: 'success'
                    })
                    setTimeout( () => {this.progressFlag = false}, 1000) // 一秒后关闭进度条
                    this.fileIs = true;
                    this.fileUp = false;
                    console.log('this.enclosure--->',this.enclosure)
                    this.$emit("enclosureData",this.enclosure,this.question)
                }).catch(err => {
                    this.$message({
                        showClose: true,
                        message: '上传失败',
                        type: 'error'
                    })
                    this.progressFlag = false
                    console.debug(err)
                });
            } catch(e){
                this.$message({
                    showClose: true,
                    message: '上传失败',
                    type: 'error'
                })
                this.progressFlag = false
                console.debug(e)
            }
        },
        beforeRemove(file,index) {
          if (this.fileIs) {
            // return this.$confirm(`确定移除 ${ file.name }？`).then(()=>{
              this.enclosure.enclosureName = '';
              this.fileData = {};
              this.enclosure.splice(index,1);
              this.fileUp = true;
              this.$emit("enclosureData",this.enclosure,this.question)
              this.progressFlag = false
              this.$emit("deleUpload",this.question)
            // });
          }
        },
        handleExceed(files) {
          this.$message.warning('当前限制选择 10 ，本次选择了' + `${files.length}` + '个文件');
        },
        handleSuccess(files) {
          console.log(files,'chenggong')
        },
        uploadZipProcess(file, fileList) {
          this.enclosure.fileList = fileList;
          this.progressFlag = true; // 显示进度条
        },
        clearFile (e,item, index) {
            if (e && e.stopPropagation) {
                e.stopPropagation();
            } else {
                window.event.cancelBubble = true;
            }
            this.fileData = item
            this.beforeRemove(this.fileData,index);
            
            
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep {
	.el-icon-tickets {
		color: #1c74ff;
		margin-right: 4px;
		font-size: 14px;
	}
	.sub {
		background: #fff;
		border-radius: 4px;
		border: 1px solid #316fff;
		height: 34px;
		line-height: 34px;
		text-align: center;
		padding: 0;
		background: none;
		cursor: pointer;
		color: #316fff;
		margin: 16px 0px 4px 0px;
		padding: 0 8px;
	}
	&.el-upload {
		text-align: left;
		width: 100%;
		cursor: pointer;
		display: block;
		.el-icon-upload {
			position: absolute;
			top: 12px;
			left: 50%;
			margin-left: -56px;
			font-size: 24px;
		}
	}
	&.el-upload-list {
		position: relative;
		float: left;
		width: 100%;
		.el-upload-list__item-name {
			width: 220px;
		}
	}
}
.sub {
	&:hover {
		background: none;
	}
}
.upload-tip {
	padding-top: 5px;
	line-height: 18px;
	font-size: 12px;
	color: #999;
}
.progr {
	position: absolute;
	top: 53px;
	width: 100%;
	height: 20px;
}
.e-icon-plus {
	width: 15px;
	height: 15px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		width: 14px;
		margin-left: -18px;
		margin-top: -1px;
		border-top: 2px solid;
	}
	&::after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		height: 14px;
		margin-left: -12px;
		margin-top: -7px;
		border-left: 2px solid;
	}
}
.zipList {
	text-align: left;
	height: 34px;
	padding: 4px 8px;
	border: 1px dashed #d6deea;
	background: #f8faff;
	border-radius: 4px;
	margin-top: 8px;
	cursor: default;
	span {
		float: left;
		height: 34px;
		line-height: 34px;
		font-size: 12px;
	}
	.zip-icon {
		width: 14px;
		padding-left: 5px;
		background-size: 14px 14px;
	}
	.file-name {
		display: inline-block;
		max-width: 142px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.el-icon-close {
		cursor: pointer;
	}
	.file-change {
		padding-left: 10px;
		color: #95accf;
	}
	.cl {
		float: right;
		width: 24px;
		text-align: center;
		z-index: 99999;
	}
}

</style>
