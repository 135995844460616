<template>
 <div class='examresultsBox' v-if="Object.values(resultsobj).length>0">
    <el-row :gutter="24">
        <el-col :span="num">
            <Examresultsdetail :resultsobj="resultsobj"/>
            <Examresultslist :resultsobj="resultsobj" class="examresultsBoxtop"/>
        </el-col>
        <!-- <el-col :span="24-num">
            <Examsheet/>
        </el-col> -->
    </el-row>
     
 </div>
</template>

<script>
import Examresultsdetail from '../block/front/examresultsdetail.vue'
import Examresultslist from '../block/front/examresultslist.vue'
// import Examsheet from '../block/front/examsheet.vue'
export default {
    name:"examresults",
    components:{
        Examresultsdetail,
        Examresultslist,
        // Examsheet
    },
    data () {
        return {
            num:24,
            resultsId:'',
            resultsobj: {},
        };
    },
    mounted(){
        this.resultsId = this.$route.query.userPaperId;
    },
    methods: {
        findUserPaperSurface() {
            let params = {
                userPaperId: this.resultsId,
            }
            this.$api.exam.findUserPaperSurface(
                params
            ).then(data => {
                data.data.useDuration = this.timeFormatter(data.data.useDuration);
                this.resultsobj = data.data;
            })
        },
        timeFormatter(time) {
            const hours = Math.floor(time / 60 / 60);
            const minutes = Math.floor((time / 60) % 60);
            const seconds = Math.floor(time % 60);
            var hours1 = hours < 10 ? "0" + hours : hours;
            var minutes1 = minutes < 10 ? "0" + minutes : minutes;
            var seconds1 = seconds < 10 ? "0" + seconds : seconds;
            return hours1 + "时" + minutes1 + "分" + seconds1 + "秒";
        }
    },
    created(){
        this.findUserPaperSurface();
    }
        
}

</script>
<style lang='scss' scoped>
    @import "../asset/css/examresults.scss"
</style>