<template>
    <div v-if="showpage" class="exam-detail-page testPaperDetails m-container">
        <div class="exam-detail clearfix">
            <div class="backButton">
                <span @click="goBack">
                     <i class="back el-icon-arrow-left"></i>
                     返回</span>
            </div>
            <div class="exam-left fl">
                <div class="exam-top">
                    <div class="exam-top-left">
                        <div class="exam-top-title">
                            <h1 :title="examDetail.examName">
                              {{ examDetail.examName | ellipsis }}
                            </h1>
                            <span v-if="frontendConfig == 'true'">{{examDetail.type==1? '模拟考试' : '正式考核'}}</span>

                        </div>
                        <div class="bottom">
                            <span>总分数：{{ examDetail.totalScore }}</span>
                            <span>及格分：{{ examDetail.passingScore }}</span>
                            <span class="line"></span>
                            <span>答题人：{{ examDetail.userName }}</span>
                            <span>考试时间：{{ examDetail.enterTime }}</span>
                            <span>用时：{{examDetail.useDuration}}</span>
                        </div>
                    </div>
                    <div class="exam-top-right">
                        <div class="titles"><span>{{examDetail.score}}</span>分</div>
                        <div class="time" v-if="!examDetail.combination">
                          <span>答对：{{examDetail.rightCount}}</span>
                          <span>答错：{{examDetail.errorCount}}</span>
                          <span>未答：{{examDetail.emptyCount}}</span>
                        </div>
                        <span class="exam-img">
                            <img class="img" :src="unanswered?img6:examDetail.isPass==0?img1:img2" alt="">
                            <span class="span" v-if="unanswered">
                              未作答
                            </span>
                            <span v-else>
                                <span v-if="examDetail.isPass==0" class="span bujigeColor">
                                  不及格
                                </span>
                                <span v-else class="span jigeColor">
                                  及格
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="scroll">
                    <div v-if="examDetail.paperType == 1 || examDetail.paperType == 3 || examDetail.paperType == 4">
                        <div
                            v-for="(item, index) in questionsList"
                            :key="index"
                            class="list"
                        >
                            <div class="test-questions">
                                <div
                                    v-if="item.questionList.length > 0"
                                    class="question-type"
                                >
                                    <span class="line"></span>
                                    <span class="h1">
                                        {{
                                            questionType[item.type]

                                        }} </span>
                                    <span class="small">
                                        (共{{ item.questionList.length }}题，共{{ item.totalMark }}分)
                                    </span>
                                </div>
                            </div>
                            <template>
                                <div
                                    v-for="(question, index1) in item.questionList"
                                    :id="'s' + question.questionId"
                                    :key="index1"
                                    class="questions"
                                >
                                    <ul class="cpStOption">
                                        <div class="subject">
                                            <span class="score">{{ question.score | grade }}分</span>
                                            <h1 class="subject-title">
                                                <span class="questionNo">{{ question.questionNo + 1 }}.</span>
                                                <p class="subject-content" v-html="question.title"></p>
                                            </h1>
                                        </div>
                                        <div class="topicOptions">
                                            <template>
                                                <li
                                                    v-for="(option, index2) in question.options"
                                                    :key="index2"
                                                    class="options"
                                                >
                                                    <span class="option-text" >
                                                        {{ option.optionNumber }}
                                                    </span>
                                                    <p v-html="option.optionContent" class="option-text-content"></p>
                                                </li>
                                                <span class="option-img" v-if="item.type != 5 && item.type != 6 && item.type!=7">
                                                    <img class="img" :src="!question.userAnswer?img5:question.result==1?img3:img4" alt="">
                                                    <span class="span color" v-if="!question.userAnswer">
                                                      未作答
                                                    </span>
                                                    <span v-else>
                                                        <span v-if="question.result==1" class="span rightColor">
                                                          回答正确
                                                        </span>
                                                        <span v-else class="span errorColor">
                                                          回答错误
                                                        </span>
                                                    </span>
                                                </span>
                                                <div class="exam-info" v-if="item.type != 5 && +item.type !== 6 && item.type!=7">
                                                    <div class="exam-answer">
                                                        <template>
                                                            <span class="answer-label">正确答案：<span class="answer-num">{{question.rightAnswer}}</span></span>
                                                            <span class="answer-label">我的答案：<span :class="!question.userAnswer?'answer-none':question.result==1?'correct':'error'">{{question.userAnswer || '未作答' }}</span></span>
                                                        </template>
                                                    </div>
                                                    <div class="signIcon">
                                                        <span class="mark-btn" :class="{ active: question.mark }" />
                                                        <span class="text">{{question.mark==1?'已标记':'标记'}}</span>
                                                    </div>
                                                </div>
                                                <div class="exam-info"  v-if="item.type == 5">
                                                    <div class="exam-answer-5">       
                                                        <div class="answer-label">
                                                            <h3>我的答案:
                                                                <span class="userScore">(我的得分{{question.userScore}}分)
                                                                </span>
                                                            </h3>

                                                            <template v-if="question.userAnswer||question.uri">
                                                                <p :class="!question.userAnswer?'answer-none':question.result==1?'correct':'error'" >{{question.userAnswer|| '未作答' }}</p>
                                                                <template v-if="question.uri && JSON.parse(question.uri).length">
                                                                    <h3>附件:</h3>
                                                                    <div v-for="(item,index) in JSON.parse(question.uri)" :key="index" style="margin-top:8px;" >
                                                                        <a :href="item.url" :download="item.name"><i class="el-icon-document"></i>{{item.name}}</a>
                                                                    </div>
                                                                </template>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="signIcon">
                                                        <span class="mark-btn" :class="{ active: question.mark }" />
                                                        <span class="text">{{question.mark==1?'已标记':'标记'}}</span>
                                                    </div>
                                                </div>
                                                <div class="exam-info"  v-if="item.type == 6">
                                                    <div class="exam-answer-6">  
                                                        <div class="answer-label">
                                                            <h3>我的答案:
                                                                <span class="userScore">(我的得分{{question.userScore}}分)
                                                                </span>
                                                            </h3>
                                                            <template v-if="question.userAnswer2Complete">
                                                                <div v-for="(completionItem,completionIndex) in question.userAnswer2Complete" :key="'completionIndex'+completionIndex" class="completionItem">
                                                                    <span class="label">第{{completionIndex+1}}空：</span>
                                                                    <span class="answer" :style="+completionItem.result===1?'color:#306FFF' :'color:#FF4655;'">{{completionItem.optionContent || '未作答'}}</span>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <div class="signIcon">
                                                        <span class="mark-btn" :class="{ active: question.mark }" />
                                                        <span class="text">{{question.mark==1?'已标记':'标记'}}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="completionRightAnswer answerList" v-if="+item.type === 6">
                                            <div class="answer-label">
                                              正确答案：
                                            <p 
                                                class="answer-num"
                                                v-for="(completionRightAnswer,completionRightAnswerIndex) in question.rightAnswer.split(',')" 
                                                :key="'completionRightAnswerIndex'+completionRightAnswerIndex"
                                            ><span class="label">第{{completionRightAnswerIndex+1}}空：</span>{{completionRightAnswer}}</p>
                                            </div>
                                        </div>  
                                        <div :class="['answerList', question.remark?'answer-bgc':'']" v-if="item.type!=7">
                                            <span class="answer-label" >试题解析：</span>
                                            <span v-if="question.remark"  class="answer-con" v-html="question.remark"></span>
                                            <span v-else class="answer-con">无</span>
                                        </div>
                                    </ul>
                                    <combined-question :obj="question"  :typest="item.type" v-if="item.type==7"></combined-question>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-if="examDetail.paperType == 2" class="practicalOperation">
                        <div
                            v-for="(item, index) in drillQuestions"
                            :id="'s' + item.questionSequence"
                            :key="index"
                            class="questions"
                        >
                            <ul class="cpStOption">
                                <div class="subject">
                                    <span class="score">{{ item.questionScore | grade }}分</span>
                                    <h1 class="subject-title">
                                        <span>{{ index + 1 }}.</span>
                                        <p class="subject-content" v-html="item.source"></p>
                                    </h1>
                                </div>
                                <div class="topicOptions">
                                    <span class="option-img" v-if="item.type != 5 && item.type != 6 && item.type!=7">
                                        <img class="img" :src="!item.reply?img5:item.result==1?img3:img4" alt="">
                                        <span class="span color" v-if="!item.reply">
                                          未作答
                                        </span>
                                        <span v-else>
                                            <span v-if="question.result==1" class="span">
                                              回答正确
                                            </span>
                                            <span v-else class="span">
                                              回答错误
                                            </span>
                                        </span>
                                    </span>
                                    <div class="answerList answer-bgc">
                                        <span class="answer-label">我的作答：</span><span class="myscore">(我得分<span>{{ item.score | grade }}</span>分)</span>
                                        <p v-html="!item.reply ? '未作答' : item.code" class="answer-con"></p>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref="examRight"
                class="exam-right fr"
            >
                <div class="answer-box">
                    <div class="text">
                        <svg-icon icon-class="datika"></svg-icon>
                        答题卡
                    </div>
                    <div
                        ref="stCount"
                        class="answer-num"
                    >
                        <div v-if="examDetail.paperType == 1 || examDetail.paperType == 3 || examDetail.paperType == 4">
                            <div
                                v-for="(mark) in questionsList"
                                :key="mark.id"
                                class="list"
                            >
                                <h1 class="titleh1" v-if="mark.questionList.length > 0">
                                    <span class="line" />
                                    <span class="type">
                                                {{
                                                    questionType[mark.type]
                                                }}
                                    </span>
                                    <div class="subQuestionBox" v-if="mark.type == 7">
                                        <div v-for="(subQuestion, subIndex) in mark.questionList" :key="subIndex">
                                            <div>
                                                <span>组合题{{subIndex+1}}</span>
                                                <span class="score">（共{{subQuestion.subDtoList.length}}题 合计{{subQuestion.score}}分）</span>
                                            </div>
                                            <a
                                                v-for="(item, index1) in subQuestion.subDtoList"
                                                :key="index1"
                                                :class="['pointer',item.userAnswer?(item.result ? 'ydt' : 'bjt'):'wd']"
                                                @click.prevent="anchor('sub' + item.id)"
                                            >
                                                <span>{{ subQuestion.questionNo + 1 }}.{{ index1 + 1 }} </span>
                                                <img
                                                    v-if="item.mark"
                                                    src="../asset/images/Path@2x.png"
                                                    class="Path"
                                                    alt=""
                                                >
                                            </a>
                                        </div>
                                    </div>
                                    <span v-else class="score">（共{{mark.questionList.length}}题 合计{{mark.totalMark}}分）</span>
                                </h1>
                                <template v-if="mark.type != 7">
                                    <a
                                        v-for="(item, index1) in mark.questionList"
                                        :key="index1"
                                        :class="['pointer',item.userAnswer?(item.result ? 'ydt' : 'bjt'):'wd']"
                                        @click.prevent="anchor('s' + item.questionId)"
                                    >
                                        <span>{{ item.questionNo + 1 }}</span>
                                        <img
                                            v-if="item.mark"
                                            src="../asset/images/Path@2x.png"
                                            class="Path"
                                            alt=""
                                        >
                                    </a>
                                </template>
                            </div>
                        </div>
                        <div v-if="examDetail.paperType == 2" class="practicalOperation-list">
                            <h1>实操题</h1>
                            <a
                                v-for="(item, index) in drillQuestions"
                                :key="index"
                                :class="['pointer',item.reply?(item.result ? 'ydt' : 'bjt'):'wd']"
                                @click.prevent="anchor('s' + item.questionSequence)"
                            >
                                <span>{{ index + 1 }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SDK from '@wd/lowcode-ss-pc'
import CombinedQuestion from './combined'
export default {
    components:{CombinedQuestion},
  name: 'testPaperDetails',
  show: true,
    data() {
        return {
            frontendConfig:false,
            showpage: true,
            examDetails:{},
            examDetail: {},
            questionsList: [], // 试题列表
            drillQuestions: [], // 实操题试题列表
            userPaperId: this.$route.query.userPaperId,
            businessId: this.$route.query.businessId,
            examId: this.$route.query.examId,
            img1: require('../../../assets/images/common/bujige.png'),
            img2: require('../../../assets/images/common/jige.png'),
            img3: require('../../../assets/images/common/answer-right.png'),
            img4: require('../../../assets/images/common/answer-error.png'),
            img5: require('../../../assets/images/common/answer-no.png'),
            img6: require('../../../assets/images/common/weizuoda.png'),
            winH: window.screen.height,
            unanswered: false,
            questionType:{
                1:'单选题',
                2:'多选题',
                3:'判断题',
                4:'高阶题',
                5:'问答题',
                6:'填空题',
                7:'组合题'
            }
        };
    },
    filters: {
        ellipsis(msg){
            if(msg && msg.length>34){
            return msg.substr(0, 34)+'...'
            }
            return msg
        },
        grade(msg){
            if (msg%1 === 0) {
                return msg+'.0'
            } else {
                return msg.toFixed(1)
            }
        },
    },
    mounted() {
    },
    created() {
        this.findUserPaperInfo();
       let frontendConfigList =  SDK.getConfigruation(this.$store).frontendConfig;
       frontendConfigList.map(item=>{
           if(item.key === 'isShowexamTag'){
               console.log('item---->777', item)
               this.frontendConfig = item.value;
           }
       })
    },
    methods: {
        timeFormatter(time) {
            console.log(time,'time')
            const hours = Math.floor(time / 60 / 60);
            const minutes = Math.floor((time / 60) % 60);
            const seconds = Math.floor(time % 60);
            var hours1 = hours < 10 ? "0" + hours : hours;
            var minutes1 = minutes < 10 ? "0" + minutes : minutes;
            var seconds1 = seconds < 10 ? "0" + seconds : seconds;
            if(hours === 0){
                return minutes1 + '分' + seconds1 + '秒';
            } else if(minutes === 0){
                return seconds1 + '秒';
            } else {
                return hours1 + '时' + minutes1 + '分' + seconds1 + '秒';
            }
        },
        findUserPaperInfo() {
            console.log('this.userPaperId---->',this.userPaperId)
            this.$api.exam
                .findUserPaperInfo(this.userPaperId)
                .then(res => {  
                    this.examDetails = res.data
                    console.log('res----->', res)
                    this.showpage = res.data ? true : false;
                    this.examDetail = res.data;
                    this.examDetail.useDuration = this.timeFormatter(this.examDetail.useDuration);
                    console.log(this.examDetail.useDuration,'this.examDetail.useDuration')
                    if(!this.examDetail.score){
                        // 是否有分数
                        if (this.examDetail.emptyCount == this.examDetail.totalCount) {
                            this.unanswered = true;
                        } else this.unanswered = false;
                    }else this.unanswered = false;
                    // 内容类型(;1:试卷;2:实训)
                    if (this.examDetail.paperType == 1 || this.examDetail.paperType == 3 || this.examDetail.paperType == 4) {
                        var subjectList = res.data.examQuestionInfos;
                        this.questionsList = [];
                        subjectList.forEach((item,index)=>{
                            item.questionNo = index // 根据下标 定为题号
                            // 填空题如果只有一个空并且未作答的情况
                            if(item.type === 6 && item.userAnswer === '' && item.completeInputNum){
                                 item.userAnswer2Complete = [];
                                for(let i = 0; i < item.completeInputNum ; i++){
                                    item.userAnswer2Complete.push({
                                        id: null,
                                        optionContent: null,
                                        optionContentWithoutTag: null,
                                        optionNumber: null,
                                        optionScore: null,
                                        questionId: null,
                                        result: 0,
                                        sort: null,
                                    })
                                }
                          
                            }
                            if (this.questionsList.length == 0) {
                                this.questionsList.push({
                                    totalMark: '',
                                    type: item.type,
                                    questionList: [
                                        item
                                    ]
                                })
                            } else {
                                var have = this.questionsList.some((ele)=>{
                                    if (ele.type == item.type) {
                                        return true;
                                    }
                                })
                                if (have) {
                                    this.questionsList.forEach((ele, index1)=>{
                                        if (ele.type == item.type) {
                                            this.questionsList[index1].questionList.push(item);
                                        }
                                    })
                                } else {
                                    this.questionsList.push({
                                        totalMark: '',
                                        type: item.type,
                                        questionList: [
                                            item
                                        ]
                                    })
                                }
                            }
                        })
                        this.questionsList.forEach((ele)=>{
                            var totalScore = 0;
                            ele.questionList.forEach(item=>{
                                totalScore += item.score;
                            })
                            if((''+totalScore).indexOf('.') !==-1){
                                ele.totalMark = totalScore.toFixed(2);
                            }else{
                                ele.totalMark = totalScore
                            }
                            
                        })
                    } else if (this.examDetail.paperType == 2) {
                        this.drillQuestions = this.examDetail.drillQuestions;
                    }
                    
                    
                    console.log('questionsList',this.questionsList);
                    
                })
        },
        // 返回
        goBack() {
            if (this.$route.name == 'testPaperDetails') {
                this.$router.go(-1)
            }
        },
        // 点击题号锚点
        anchor(anchorName,) {
            const anchorElement = document.getElementById(anchorName)
            if (anchorElement) {
                anchorElement.scrollIntoView()
            }
        },
    }
};
</script>
<style lang="scss" src="../asset/css/testPaperDetails.scss" scoped>

</style>
<style lang="scss" scoped>
.exam-detail {
	.exam-right {
		.answer-box {
			.answer-num {
				.pointer {
					span {
						font-size: 12px;
					}
				}
			}
		}
	}
	.completionRightAnswer {
		.answer-label {
			.answer-num {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666;
				line-height: 24px;
				padding: 0;
			}
		}
	}
}
.completionItem {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	line-height: 24px;
	.label {
		color: #666;
	}
	.answer {
		color: #306fff;
	}
}
::v-deep {
	&.el-scrollbar__wrap {
		overflow-x: hidden;
	}
}
.testPaperDetails {
	.option-text-content {
		span {
			font-size: 100%;
		}
		img {
			height: auto !important;
			max-width: 100%;
			vertical-align: middle;
		}
		ol {
			margin-left: 40px;
			list-style-type: decimal;
		}
		ul {
			margin-left: 40px;
			list-style-type: disc;
		}
	}
	.answer-con {
		span {
			font-size: 100%;
		}
		img {
			height: auto !important;
			max-width: 90%;
			vertical-align: middle;
		}
		ol {
			margin-left: 40px;
			list-style-type: decimal;
		}
		ul {
			margin-left: 40px;
			list-style-type: disc;
		}
	}
	.subject-content {
		span {
			font-size: 100%;
		}
		img {
			max-width: 100%;
			height: auto !important;
			vertical-align: middle;
		}
		ol {
			margin-left: 40px;
			list-style-type: decimal;
		}
		ul {
			margin-left: 40px;
			list-style-type: disc;
		}
	}
}


</style>
