<!-- 
 * @description: 组合题
 * @fileName: combined.vue 
 * @date: 2023-03-01 16:10:41
!-->
<template>
    <div>
        <div v-for="(question,inx) in obj.subDtoList" :key="inx" class="combined-list-box questions">
            <div class="cpStOption" :id="'sub' + question.id">
                <div class="subject combinedcss">
                    <span class="leftNo">{{obj.questionNo+1}}.{{inx+1}}【{{questionType[question.type]}} {{ question.score | grade }}分】</span>
                    <h1 class="subject-title">
                        <p class="subject-content" v-html="question.title"> {{ question.title }}</p>
                    </h1>
                </div>
                <div class="topicOptions">
                    <template>
                        <template v-if="question.type!=5">
                            <div
                                v-for="(option, index2) in question.optionList"
                                :key="index2"
                                class="options"
                            >
                            <span class="option-text" >
                                {{ option.optionNumber }}
                            </span>
                            <p v-html="option.optionContent" class="option-text-content">{{ option.optionContent }}</p>
                        </div>
                        </template>
                        <span class="option-img" v-if="question.type != 5 && question.type != 6">
                            <img class="img" :src="!question.userAnswer?img5:question.result==1?img3:img4" alt="">
                            <span class="span color" v-if="!question.userAnswer">
                                未作答
                            </span>
                            <span v-else>
                                <span v-if="question.result==1" class="span rightColor">
                                    回答正确
                                </span>
                                <span v-else class="span errorColor">
                                    回答错误
                                </span>
                            </span>
                        </span>
                        <div class="exam-info" v-if="question.type != 5 && +question.type !== 6">
                            <div class="exam-answer">
                                <template>
                                </template>
                                <template>
                                    <span class="answer-label">正确答案：<span class="answer-num">{{question.rightAnswer}}</span></span>
                                    <span class="answer-label">我的答案：<span :class="!question.userAnswer?'answer-none':question.result==1?'correct':'error'">{{question.userAnswer || '未作答' }}</span></span>
                                </template>
                            </div>
                            <div class="signIcon">
                                <span class="mark-btn" :class="{ active: question.mark }" />
                                <span class="text">{{question.mark==1?'已标记':'标记'}}</span>
                            </div>
                        </div>
                        <div class="exam-info"  v-if="question.type == 5">
                            <div class="exam-answer-5">       
                                <div class="answer-label">
                                    <h3>我的答案:
                                        <span class="userScore">(我的得分{{question.userScore}}分)
                                        </span>
                                    </h3>

                                    <template v-if="question.userAnswer||question.uri">
                                        <p :class="!question.userAnswer?'answer-none':question.result==1?'correct':'error'" >{{question.userAnswer|| '未作答' }}</p>
                                        <template v-if="question.uri && JSON.parse(question.uri).length">
                                            <h3>附件:</h3>
                                            <div v-for="(item,index) in JSON.parse(question.uri)" :key="index" style="margin-top:8px;" >
                                                <a :href="item.url" :download="item.name"><i class="el-icon-document"></i>{{item.name}}</a>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                            <div class="signIcon">
                                <span class="mark-btn" :class="{ active: question.mark }" />
                                <span class="text">{{question.mark==1?'已标记':'标记'}}</span>
                            </div>
                        </div>
                        <div class="exam-info"  v-if="question.type == 6">
                            <div class="exam-answer-6">  
                                <div class="answer-label">
                                    <h3>我的答案:
                                        <span class="userScore">(我的得分{{question.userScore}}分)
                                        </span>
                                    </h3>
                                    <template v-if="question.userAnswer2Complete">
                                        <div v-for="(completionItem,completionIndex) in question.userAnswer2Complete" :key="'completionIndex'+completionIndex" class="completionItem">
                                            <span class="label">第{{completionIndex+1}}空：</span>
                                            <span class="answer" :style="+completionItem.result===1?'color:#306FFF' :'color:#FF4655;'">{{completionItem.optionContent || '未作答'}}</span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="signIcon">
                                <span class="mark-btn" :class="{ active: question.mark }" />
                                <span class="text">{{question.mark==1?'已标记':'标记'}}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="completionRightAnswer answerList" v-if="+question.type === 6">
                    <div class="answer-label">
                        正确答案：
                        <p 
                        class="answer-num"
                        v-for="(completionRightAnswer,completionRightAnswerIndex) in question.rightAnswer.split(',')" 
                        :key="'completionRightAnswerIndex'+completionRightAnswerIndex"
                        ><span class="label">第{{completionRightAnswerIndex+1}}空：</span>{{completionRightAnswer}}</p>
                    </div>
                </div>  
                <div :class="['answerList', question.remark?'answer-bgc':'']">
                    <span class="answer-label" >试题解析：</span>
                    <span v-html="question.remark" class="answer-con">{{question.remark|| '无'}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'CombinedQuestion',
    props:{
        typest:{
            type:Number,
            default:0,
        },
        obj:{
            type:Object,
            default:()=>{}
        }
    },
    filters: {
        grade(msg){
            if (msg%1 === 0) {
                return msg+'.0'
            } else {
                return msg.toFixed(1)
            }
        },
    },
    data(){
        return{
            img1: require('../../../assets/images/common/bujige.png'),
            img2: require('../../../assets/images/common/jige.png'),
            img3: require('../../../assets/images/common/answer-right.png'),
            img4: require('../../../assets/images/common/answer-error.png'),
            img5: require('../../../assets/images/common/answer-no.png'),
            img6: require('../../../assets/images/common/weizuoda.png'),
            questionType:{
                1:'单选题',
                2:'多选题',
                3:'判断题',
                4:'高阶题',
                5:'问答题',
                6:'填空题',
                7:'组合题'
            }
        }
    },
}
</script>
<style lang="scss" src="../asset/css/testPaperDetails.scss" scoped>

</style>
<style lang="scss" scoped>
.exam-detail{
  .exam-right {
    .answer-box .answer-num .pointer span{
      font-size:12px;
    } 
  }
}
.exam-detail {
	.exam-left {
		.scroll {
			.combined-list-box {
				padding-left: 44px;
			}
		}
	}
	.completionRightAnswer {
		.answer-label {
			.answer-num {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666;
				line-height: 24px;
				padding: 0;
			}
		}
	}
}
.exam-detail .exam-left .scroll .combined-list-box .cpStOption .topicOptions,
.exam-detail .exam-left .scroll .combined-list-box .cpStOption .answerList {
	margin-left: 0 !important;
}
.completionItem {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	line-height: 24px;
	.label {
		color: #666;
	}
	.answer {
		color: #306fff;
	}
}
::v-deep {
	&.el-scrollbar__wrap {
		overflow-x: hidden;
	}
}
.testPaperDetails {
	.option-text-content {
		span {
			font-size: 100%;
		}
		img {
			height: auto !important;
			max-width: 100%;
			vertical-align: middle;
		}
		ol {
			margin-left: 40px;
			list-style-type: decimal;
		}
		ul {
			margin-left: 40px;
			list-style-type: disc;
		}
	}
	.answer-con {
		span {
			font-size: 100%;
		}
		img {
			height: auto !important;
			max-width: 90%;
			vertical-align: middle;
		}
		ol {
			margin-left: 40px;
			list-style-type: decimal;
		}
		ul {
			margin-left: 40px;
			list-style-type: disc;
		}
	}
	.subject-content {
		span {
			font-size: 100%;
		}
		img {
			max-width: 100%;
			height: auto !important;
			vertical-align: middle;
		}
		ol {
			margin-left: 40px;
			list-style-type: decimal;
		}
		ul {
			margin-left: 40px;
			list-style-type: disc;
		}
	}
}

</style>

