<template>
  <div class="exam-detail-page m-container handPaper">
    <div class="exam-detail clearfix">
      <div class="backButton">
        <div @click="goBack">
          <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
        </div>
        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/exam-result-title.png" alt="">
      </div>
      <div class="exam-bottom">
        <div class="exam-top">
          <!-- 
            isReadOver 0 未批阅 1 已批阅
            isPass 0 及格 1 不及格
            -->
          <img
            :src="`https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/statusBg${!examDetail.isReadOver ? '1' : examDetail.isPass ? '2' : '3'}.png`"
            class="statusBg" alt="">
          <p class="score" :class="{ score1: !examDetail.isPass }" v-if="examDetail.score != null"> {{ examDetail.score }}分</p>
          <template v-if="!examDetail.isReadOver">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/exam-status1.png" class="exam-status"
              alt="">
          </template>
          <template v-else>
            <img v-if="examDetail.isPass"
              src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/exam-status2.png" class="exam-status"
              alt="">
            <img v-else-if="!examDetail.isPass"
              src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/exam-status3.png" class="exam-status"
              alt="">
          </template>
        </div>
        <div class="questionBlock" v-if="examDetail.isReadOver">
          <template v-if="examDetail.combination">
            <div class="questionItem">
              <div class="left">
                <p class="score">{{ examDetail.totalScore }}</p>
                <p class="label">总分数(分)</p>
              </div>
              <div class="right">
                <img class="questionIcon" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/scoreIcon.png" alt="">
              </div>
            </div>
          </template>
          <template v-else>
            <div class="questionItem">
              <div class="left">
                <p class="score">{{ examDetail.rightCount }}</p>
                <p class="label">答对(道)</p>
              </div>
              <div class="right">
                <img class="questionIcon" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/successIcon.png" alt="">
              </div>
            </div>
            <div class="questionItem">
              <div class="left">
                <p class="score">{{ examDetail.errorCount }}</p>
                <p class="label">答错(道)</p>
              </div>
              <div class="right">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/errorIcon.png"
                  class="questionIcon" alt="">
              </div>
            </div>
            <div class="questionItem">
              <div class="left">
                <p class="score">{{ examDetail.emptyCount }}</p>
                <p class="label">未答(道)</p>
              </div>
              <div class="right">
                <img class="questionIcon" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/noIcon.png" alt="">
              </div>
            </div>
          </template>
          
          <div class="questionItem">
            <div class="left">
              <p class="score">{{ examDetail.questionNum }}</p>
              <p class="label">总题数(道)</p>
            </div>
            <div class="right">
              <img class="questionIcon" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/exam/totalIcon.png" alt="">
            </div>
          </div>
        </div>
        <div class="examInfo">
          <div class="examName text">
            <span class="label">考试名称：</span>
            <span class="val">{{ examDetail.examName }}</span>
            <p class="totalScore">总分 {{ examDetail.totalScore }}</p>
          </div>
          <p class="text">
            <span class="label">考试时间：</span>
            <span class="val">{{ examDetail.enterTime }}</span>
          </p>
          <p class="text">
            <span class="label">考试用时：</span>
            <span class="val">{{ examDetail.useDuration | timeFormatter }}</span>
          </p>
          <template v-if="!examDetail.isValid">
            <p class="text">
              <span class="label">异常记录：</span>
              <span class="val">{{ examDetail.exceptionTime }}</span>
            </p>
            <p class="text">
              <span class="label">异常原因：</span>
              <span class="val">{{ examDetail.remark }}</span>
            </p>
          </template>
        </div>
        <div class="scroll">
          <div class="scroll-btn">
            <div class="submit-btn-see" v-if="examDetail.isReadOver==1&&examDetail.isReadResult==1">
              <span class="button" @click="testPaper">查看试卷详情</span>
            </div>
            <div class="submit-btn" v-if="$route.query.type !== 'historyList'">
              <div v-if="haveFrequency">
                <el-button v-if="examDetail.result == 1" type="primary" plain @click="submitExam">重新考试</el-button>
                <el-button v-else type="primary" @click="submitExam">重新考试</el-button>
              </div>
              <el-button v-else-if="noFrequency" type="primary" class="noneNum">已无考试剩余次数</el-button>
              <div v-else-if="again">
                <el-button v-if="examDetail.result == 1" type="primary" plain @click="submitExam">重新考试</el-button>
                <el-button v-else type="primary" @click="submitExam">重新考试</el-button>
              </div>
              <span v-else-if="overdue" @click="submitExam" class="end-exam">考试已结束</span>
              <!-- <el-button v-else-if="overdue" type="primary" @click="submitExam">不在考试周期内</el-button> -->
              <p v-if="haveFrequency" class="num">考试剩余机会：{{ frequency }}次/ 共{{ examDetail.examCount }}次</p>
              <p v-else-if="noFrequency" class="num">考试剩余机会：{{ 0 }}次/ 共{{ examDetail.examCount }}次</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog id="tip-dialog" :visible.sync="coursedialogVisible" width="400px" :before-close="handleClose">
      <svg-icon icon-class="tips" class-name="icon-img"></svg-icon>
      <p class="title">{{ text1 }}</p>
      <p class="text">{{ text2 }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">{{ submitText }}</el-button>
      </span>
    </el-dialog>
    <facedialog v-if="verification" :confirmdialog="confirmdialog" :address="verificationAddress"
      :failAddress="verificationFailAddress" :type=2 :pauseFrame="true"></facedialog>
  </div>
</template>

<script>
import SDK from '@wd/lowcode-ss-pc'
export default {
  name: 'handPaper',
  show: true,
  data() {
    return {
      confirmdialog: {
        show: true,
      },
      frontendConfig: false,
      text1: '',
      text2: '',
      submitText: '',
      examDetail: {},
      examTime: '',
      coursedialogVisible: false,
      tipsContent: '',
      flag: false,
      startTime: '',
      endTime: '',
      frequency: null,
      userPaperId: this.$route.query.userPaperId,
      businessId: this.$route.query.businessId,
      examId: this.$route.query.examId,
      //taskType为1，则为从培训任务跳过来的
      taskType: this.$route.query.taskType || null,
      // 考前人脸识别
      isFaceRecogBeforeExam: JSON.parse(sessionStorage.getItem('isFaceRecogBeforeExam')),
      // 考中人脸识别
      isFaceRecogDuringExam: JSON.parse(sessionStorage.getItem('isFaceRecogDuringExam')),
      // 考后人脸识别
      isFaceRecogCommitExam: JSON.parse(sessionStorage.getItem('isFaceRecogCommitExam')),
      // 入口
      source: this.$route.query.source,
      userName: '',
      haveFrequency: false,
      noFrequency: false,
      again: false,
      overdue: false,
      img1: require('../../../assets/images/common/jige.png'),
      img2: require('../../../assets/images/common/bujige.png'),
      img3: require('../../../assets/images/common/weizuoda.png'),
      invalid: '',
      verification: false,
      verificationAddress: {},
      verificationFailAddress: {},
      returnPath: {},
      unanswered: false,
      cheat: 0,//未违规
      loopInterval:null
    };
  },
  filters: {
    timeFormatter(time) {
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor((time / 60) % 60);
      const seconds = Math.floor(time % 60);
      var hours1 = hours < 10 ? "0" + hours : hours;
      var minutes1 = minutes < 10 ? "0" + minutes : minutes;
      var seconds1 = seconds < 10 ? "0" + seconds : seconds;
      if (hours === 0) {
        return minutes1 + '分' + seconds1 + '秒';

      } else if (minutes === 0) {
        return seconds1 + '秒';
      } else {
        return hours1 + '时' + minutes1 + '分' + seconds1 + '秒';
      }
    },
  },
  mounted() {
    if (this.source == 'examDetails') {
      this.returnPath = {
        path: '/examDetails',
        query: {
          bussinessId: this.businessId,
          examId: this.examId,
          type: this.taskType,
          dymode: this.$route.query.dymode,
          title: sessionStorage.getItem("examDetailsTitle"),
          timeSlot: sessionStorage.getItem("examDetailsTimeSlot"),
          startTime: sessionStorage.getItem("examDetailStartTime"),
          endTime: sessionStorage.getItem("examDetailendTime")
        }
      }
    } else if (this.source == 'coursecatalog') {
      this.returnPath = {
        path: '/course/catalog',
        query: {
          id: this.businessId,
          taskType: this.taskType,
          dymode: this.$route.query.dymode
        }
      }
    }
    // 定时器每3秒执行一次轮询
    this.loopInterval = setInterval(this.loop, 3000);
  },
  created() {
    this.getUserPaperSurface();
    this.returnButton();
    let frontendConfigList = SDK.getConfigruation(this.$store).frontendConfig;
    frontendConfigList.map(item => {
      if (item.key === 'isShowexamTag') {
        console.log('item---->777', item)
        this.frontendConfig = item.value;
      }
    })
  },
  destroyed() {
    clearInterval(this.loopInterval)
    window.removeEventListener('popstate', this.popstate, false);
  },
  methods: {
    loop(){
      this.getUserPaperSurface()
    },
    // 浏览器回退按钮到训练营
    returnButton() {
      if (this.$route.name == 'handPaper') {
        let that = this;
        if (window.history && window.history.pushState) {
          // history.pushState(null, null, document.URL);
          history.replaceState(null, null, document.URL);
          window.addEventListener('popstate', that.popstate, false)
        }
      }
    },
    popstate() {
      // history.pushState(null, null, document.URL)
      history.replaceState(null, null, document.URL)
    },
    goBack() {
      if (this.$route.name == 'handPaper') {
        if (!(this.source == 'examDetails' && !this.examId)) {
          this.$router.replace(this.returnPath)
        }
      }
    },
    handleClose() {
      this.coursedialogVisible = false;
    },
    // 试卷信息
    getUserPaperSurface() {
      const params = {
        userPaperId: this.userPaperId
      }
      this.$api.exam.getUserPaperSurface({params}).then((res) => {
        if (res.data && res.data != 'null') {
          this.examDetail = res.data;
          if (this.examDetail.invalidType == 1) {
            this.invalid = '人脸识别失败，强制交卷!'
          } else if (this.examDetail.invalidType == 2) {
            this.invalid = '防切屏超过次数限制，强制交卷!'
          } else if (this.examDetail.invalidType == 3) {
            this.invalid = '多人出现超过次数限制，强制交卷!'
          } else if (this.examDetail.invalidType == 4) {
            this.invalid = '不是本人超过次数限制，强制交卷!'
          } else if (this.examDetail.invalidType == 5) {
            this.invalid = '检测不到人超过次数限制，强制交卷!'
          } else if (this.examDetail.invalidType == 7) {
            this.invalid = '管理员强制交卷!'
          } else if (this.examDetail.invalidType == 9) {
            this.invalid = '系统交卷!'
          }
          if(this.examDetail.isReadOver){
              // 批阅完成以后清除定时器
              clearInterval(this.loopInterval);
          }
          if (!this.examDetail.score) {
            // 是否有分数
            if (this.examDetail.emptyCount == this.examDetail.questionNum) {
              this.unanswered = true;
            } else this.unanswered = false;
          } else this.unanswered = false;
          // this.examDetail.useDuration = this.timeFormatter(this.examDetail.useDuration);
          this.startTime = (new Date(this.examDetail.trainExamStartTime)).getTime();
          this.endTime = (new Date(this.examDetail.trainExamEndTime)).getTime();
          var nowTime = (new Date()).getTime();
          this.frequency = this.examDetail.examCount - this.examDetail.yetExamCount;
          if (this.examDetail.isLimitTime) {
            if (nowTime > this.startTime && nowTime < this.endTime) {
              if (this.examDetail.examCount == 0) {
                this.again = true;
              } else if (this.frequency > 0) {
                this.haveFrequency = true;
              } else {
                this.noFrequency = true;
              }
            } else {
              this.overdue = true;
            }
          } else {
            if (this.examDetail.examCount == 0) {
              this.again = true;
            } else if (this.frequency > 0) {
              this.haveFrequency = true;
            } else {
              this.noFrequency = true;
            }
          }
        }
      });
    },
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime)//将传入的秒的值转化为Number
      let min = 0// 初始化分
      let h = 0// 初始化小时
      let result = ''
      if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60)//获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60)//获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {//如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60)//获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      var xs = `${h.toString().padStart(2, '0')}` == '00' ? '' : `${h.toString().padStart(2, '0')}` + '小时';
      var fz = `${min.toString().padStart(2, '0')}` == '00' ? '' : `${min.toString().padStart(2, '0')} ` + '分';
      var mz = `${secondTime.toString().padStart(2, '0')}` == '00' ? '' : `${secondTime.toString().padStart(2, '0')}` + '秒';
      result = xs + fz + mz;
      this.examTime = result;
    },
    // 再考一次
    submitExam() {
      this.$store.state.testPaper = false
      var nowTime = (new Date()).getTime();
      if (this.examDetail.isLimitTime) {
        const lateTime = this.examDetail.allowLateDuration * 60 * 1000;
        if (nowTime > this.startTime && nowTime < this.endTime) {
          if (this.examDetail.allowLateDuration > 0 && nowTime > this.startTime + lateTime) {
            this.coursedialogVisible = true;
            this.text1 = '提示';
            this.text2 = '您已超过最迟开考时间！';
            this.submitText = '确定';
          } else if (this.isFaceRecogBeforeExam == 1) {
            this.verification = true;
            this.confirmdialog.show = true;
            this.verificationAddress = {
              path: '/exam/resdExam',
              query: {
                userPaperId: this.userPaperId,
                businessId: this.businessId,
                examId: this.examId,
                dymode: this.$route.query.dymode,
                taskType: this.taskType,
                source: this.source
              }
            };
            this.verificationFailAddress = this.returnPath;
          } else {
            this.$router.push({
              path: '/exam/resdExam',
              query: {
                userPaperId: this.userPaperId,
                businessId: this.businessId,
                examId: this.examId,
                dymode: this.$route.query.dymode,
                taskType: this.taskType,
                source: this.source
              }
            })
          }
        } else {
          this.coursedialogVisible = true;
          this.text1 = '提示';
          this.text2 = '已不在考试周期内';
          this.submitText = '确定';
        }
      } else {
        if (this.isFaceRecogBeforeExam == 1) {
          this.verification = true;
          this.verificationAddress = {
            path: '/exam/resdExam',
            query: {
              userPaperId: this.userPaperId,
              businessId: this.businessId,
              examId: this.examId,
              dymode: this.$route.query.dymode,
              taskType: this.taskType,
              source: this.source
            }
          };
          this.verificationFailAddress = this.returnPath;
        } else {
          this.$router.push({
            path: '/exam/resdExam',
            query: {
              userPaperId: this.userPaperId,
              businessId: this.businessId,
              examId: this.examId,
              dymode: this.$route.query.dymode,
              taskType: this.taskType,
              source: this.source
            }
          })
        }
      }
    },
    // 查看试卷详情
    testPaper() {
      if (!this.examDetail.isReadOver) {
        this.$message({
          message: '阅卷中，请耐心等待',
          type: 'warning'
        });
      } else {
        this.$store.state.testPaper = false
        this.$router.push({
          path: '/exam/testPaperDetails',
          query: {
            userPaperId: this.userPaperId,
            businessId: this.businessId,
            examId: this.examId,
            dymode: this.$route.query.dymode
          }
        })
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.scroll {
  overflow: hidden;
}

.handPaper {
  ::v-deep.el-button--primary {
    background: #316FFF;
    border-color: #316FFF;

    span {
      color: #fff;
      font-size: 16px;
    }

    &.is-plain {
      color: #409EFF;
      background: #ecf5ff;
      border-color: #b3d8ff;

      span {
        color: #409EFF !important;
      }

      &:hover {
        background-color: #409eff;

        span {
          color: #FFF !important;
        }
      }
    }
  }

  #tip-dialog {
    text-align: center;

    ::v-deep.el-dialog {
      border-radius: 10px;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding-top: 40px;
        padding-bottom: 30px;

        .icon-img {
          width: 50px;
          height: 50px;
          display: block;
          margin: 0 auto;
          margin-bottom: 19px;
        }

        .title {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1B2257;
          line-height: 34px;
          margin-bottom: 16px;
        }

        .text {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }

      ::v-deep.el-dialog__footer {
        padding: 0 65px 30px;
        text-align: center;

        .dialog-footer {
          .el-button {
            width: 240px;
            height: 40px;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
<style lang="scss" scoped>@import "../asset/css/handPaper.scss"</style>
