<template>
  <div class="shade-pc" v-if="showPcShade" :style="{'z-index': phoneLiveStatus ? 8: 10}">
    <div class="shade-pc-content">
      <img src="../../asset/images/mask_layer_h5@2x.png" alt="" class="shade-h5-img" v-show="!phoneLiveStatus">
      <img src="../../asset/images/mask_layer_pc@2x.png" class="shade-pc-img" v-show="showDetectionBtn">
    </div>
  </div>
</template>
<script>
export default {
  props:{
    showPcShade: {
      type: Boolean,
      default: false
    },
    phoneLiveStatus: {
      type: Boolean,
      default: true
    },
    showDetectionBtn: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
  .shade-pc {
    position:absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #252533;
    opacity: 0.8;
    color: #fff;
    z-index: 8;
    .shade-pc-content {
      width: 1200px;
      height: 100%;
      position: relative;
      margin: 0 auto;
      .shade-pc-img {
        position:absolute;
        right: 80px;
        bottom: 200px;
        width: 368px;
        height: 106px;
      }
      .shade-h5-img {
        position:absolute;
        right: 540px;
        top: 280px;
        width: 408px;
        height: 106px;
      }
    }
  }
</style>
