<template>
    <div>
        <a v-for="(item, index) in list" :key="index" :class="['pointer', item.result ? 'ydt' : '']" @click.prevent="anchor(item)">
            <template v-if="combinationFlag">
                <span>{{ question.questionNo + 1 }}.{{ index + 1 }} </span>
                <img v-if="item.markFlag" src="../asset/images/Path@2x.png" class="Path" alt="" />
            </template>
            <template v-else>
                <span>{{ item.questionNo + 1 }}</span>
                <img v-if="item.markFlag" src="../asset/images/Path@2x.png" class="Path" alt="" />
            </template>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        combinationFlag: {
            // 是否为组合题
            type: Boolean,
            default() {
                return true
            },
        },
        list: {
            // 循环的数组
            type: Array,
            default() {
                return []
            },
        },
        question: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    methods: {
        // 点击题号锚点
        anchor(item) {
            console.log(item, '点击的题号锚点')
            let anchorName
            if (this.combinationFlag) {
                anchorName = 'sub' + item.id
            } else anchorName = 's' + item.questionId
            const anchorElement = document.getElementById(anchorName)
            if (anchorElement) {
                anchorElement.scrollIntoView()
            }
        },
        forceUpdate() {
            this.$forceUpdate()
        },
    },
}
</script>

<style lang="scss" scoped>
.pointer {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    margin-right: 13px;
    margin-bottom: 12px;
    box-sizing: content-box;
    position: relative;
    cursor: pointer;
    background: #f9f9fa;
    border: 1px solid #f9f9fa;
    .Path {
        position: absolute;
        top: 0;
        right: 0;
        width: 9px;
    }
    &.ydt {
        background: rgba(49, 111, 255, 0.08);
        span {
            color: #316fff;
        }
    }
    &.bjt {
        background: #f3b14f;
    }
    span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
        line-height: 32px;
    }
}
</style>
